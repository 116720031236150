var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-list',{staticClass:"py-0",attrs:{"three-line":"","dense":"","height":_vm.itemHeight}},[_c('v-list-item',{staticClass:"pl-2 pr-1",class:_vm.getStatusColor(_vm.source.status),attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-list-item-content',{staticClass:"black--text py-1"},[_c('v-list-item-title',{class:_vm.source.status == 'PENDING'
              ? _vm.source.source == 'CUSTOMER_ANDROID'
                ? 'pink lighten-2'
                : _vm.source.source == 'CUSTOMER_IOS'
                ? 'pink lighten-2'
                : _vm.source.source == 'BRAND_ANDROID'
                ? 'pink lighten-2'
                : _vm.source.source == 'BRAND_IOS'
                ? 'pink lighten-2'
                : ''
              : ''},[(_vm.source.preferredDriver)?_c('v-icon',{attrs:{"title":_vm.$t('ConsoleOrderListItem.preferredDriver') +
              ' ' +
              _vm.source.preferredDriver.firstName +
              ' ' +
              _vm.source.preferredDriver.lastName,"color":"red darken-4","dense":"","small":""}},[_vm._v("mdi-account-heart")]):_vm._e(),_vm._v(" "+_vm._s(_vm.source.id.slice(0, 8) + " | ")+" "+_vm._s(_vm._f("formatDateTimeMini")(_vm.source.createdAt))+" "+_vm._s(" | " + _vm.$t("orderType." + _vm.source.type) + " | " + _vm.$t("orderStatus." + _vm.source.status)))],1),(
            _vm.source.type == 'TIME' &&
            ['NEW', 'PLANNED', 'PENDING', 'TRANSFERRED'].includes(
              _vm.source.status
            )
          )?_c('v-list-item-subtitle',{class:_vm.source.status == 'PENDING'
              ? 'purple darken-1 white--text pa-0 ma-0'
              : 'black--text pa-0 ma-0',attrs:{"title":_vm.$t('ConsoleOrderListItem.requestedTime')}},[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","color":_vm.source.status == 'PENDING' ? 'white' : 'red darken-4'}},[_vm._v("mdi-clock-outline")]),_vm._v(" "+_vm._s(_vm._f("formatDateShort")(_vm.source.requestedTime))+" "+_vm._s(" | " + _vm.source.presendTime)+" min.")],1):_vm._e(),(_vm.source.vehicle)?_c('v-list-item-subtitle',{class:[
            'text-truncate',
            _vm.source.providerTaxiserviceId
              ? 'red--text text--darken-4'
              : 'black--text',
          ],staticStyle:{"max-width":"310px"}},[_c('v-icon',{attrs:{"dense":"","color":_vm.source.providerTaxiserviceId ? 'red darken-4' : 'black'}},[_vm._v("mdi-taxi")]),_vm._v(_vm._s((_vm.source.driver ? (_vm.source.driver.firstName ? _vm.source.driver.firstName : "") + (_vm.source.driver.lastName ? " " + _vm.source.driver.lastName : "") : "") + " | ")+" "+_vm._s((_vm.source.vehicle.internalName ? _vm.source.vehicle.internalName : "") + (_vm.source.vehicle.brand ? " " + _vm.source.vehicle.brand : "") + (_vm.source.vehicle.model ? " " + _vm.source.vehicle.model : "") + (_vm.source.color ? " " + _vm.$t("vehicleColor_short." + _vm.source.color) : "") + (_vm.source.vehicle.lpn ? " | " + _vm.source.vehicle.lpn : "")))],1):_vm._e(),(_vm.source.note)?_c('v-list-item-subtitle',{staticClass:"d-inline-block text-truncate black--text pa-0 ma-0",staticStyle:{"max-width":"310px"}},[_c('v-icon',{staticClass:"pa-0 ma-0",attrs:{"dense":"","color":"black"}},[_vm._v("mdi-text-box-outline")]),_vm._v(" "+_vm._s(_vm.source.note))],1):_vm._e(),_vm._l((_vm.source.waypointList),function(waypoint){return _c('v-list-item',{key:waypoint.id,staticClass:"black--text pa-0 ma-0",staticStyle:{"min-height":"0px"},attrs:{"dense":"","justify":"center"}},[(waypoint.type == 'BOARD' || waypoint.type == 'DESTINATION')?_c('v-list-item-content',{staticClass:"pa-0 ma-0"},[_c('v-list-item-title',{staticClass:"d-inline-block text-truncate black--text",staticStyle:{"max-width":"300px"}},[_c('v-icon',{attrs:{"dense":"","small":"","color":"black","justify":"center","title":"Show on map"},on:{"click":function($event){return _vm.showPositionCoordinatesToMap(
                    waypoint.latitude,
                    waypoint.longitude
                  )}}},[_vm._v(_vm._s(waypoint.type == "BOARD" ? "fas fa-street-view" : waypoint.type == "DESTINATION" ? "fas fa-flag-checkered" : waypoint.type == "TRANSIT" ? "mdi-transit-connection-variant" : "mdi-map-marker"))]),(
                  waypoint.type === 'DESTINATION' &&
                  _vm.source.waypointList.length > 2
                )?_c('span',{staticStyle:{"color":"#b71c1c"}},[_vm._v(" (+) ")]):_vm._e(),_vm._v(" "+_vm._s(waypoint.address))],1)],1):_vm._e()],1)})],2),_c('v-list-item-action',{staticClass:"ml-0"},[_c('v-icon',{staticClass:"mx-auto pb-0",attrs:{"color":_vm.source.status == 'PENDING'
              ? _vm.source.source == 'CUSTOMER_ANDROID'
                ? 'pink lighten-1'
                : _vm.source.source == 'CUSTOMER_IOS'
                ? 'pink lighten-1'
                : _vm.source.source == 'BRAND_ANDROID'
                ? 'pink lighten-1'
                : _vm.source.source == 'BRAND_IOS'
                ? 'pink lighten-1'
                : 'black'
              : 'black',"title":_vm.$t('source_tooltip.' + _vm.source.source)}},[_vm._v(" "+_vm._s(_vm.source.source == "DISPATCH_TAXISERVICE" ? "mdi-face-man-outline" : _vm.source.source == "DISPATCH_TAXXIMO" ? "mdi-face-man-outline" : _vm.source.source == "DISPATCH_BROKER" ? "mdi-face-man-outline" : _vm.source.source == "DRIVER_ANDROID" ? "mdi-account-tie-outline" : _vm.source.source == "CUSTOMER_ANDROID" ? "mdi-human-greeting-variant" : _vm.source.source == "CUSTOMER_IOS" ? "mdi-human-greeting-variant" : _vm.source.source == "BRAND_ANDROID" ? "mdi-human-greeting-variant" : _vm.source.source == "BRAND_IOS" ? "mdi-human-greeting-variant" : _vm.source.source == "PBX" ? "mdi-phone-classic" : "mdi-alien-outline"))]),_c('v-icon',{staticClass:"mx-auto pb-0",attrs:{"color":_vm.source.type == 'TIME' ? 'red darken-4' : 'black',"title":_vm.$t('orderType_tooltip.' + _vm.source.type)}},[_vm._v(" "+_vm._s(_vm.source.type == "INSTANT" ? "mdi-bell-circle-outline" : _vm.source.type == "TIME" ? "mdi-clock-outline" : _vm.source.type == "STREET" ? "mdi-nature-people" : _vm.source.type == "DIRECT" ? "mdi-directions" : _vm.source.type == "REPEATED" ? "mdi-calendar-refresh" : _vm.source.type == "AIRPORT" ? "mdi-airplane-clock" : "mdi-alien-outline"))]),_c('v-btn',{attrs:{"icon":"","title":_vm.source.onlyManualSending == true
              ? _vm.$t('ConsoleOrderListItem.onlyManualSending_true')
              : _vm.$t('ConsoleOrderListItem.onlyManualSending_false')},on:{"click":function($event){return _vm.showOrderDetail(_vm.source.id)}}},[_c('v-icon',{staticClass:"py-0 my-0",attrs:{"color":_vm.source.onlyManualSending == true ? 'red darken-4' : 'black'}},[_vm._v("mdi-information")])],1)],1)]},proxy:true}])}),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }